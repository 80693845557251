import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import ServicesData from '../servicesDetail.json'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { Link } from 'react-router-dom'
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import Icon from '@mui/icons-material/Widgets';
import CircleIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  const normalizedServiceName = params.serviceDetailsId.replace(/-/g, '').toLowerCase();

  const serviceKey = Object.keys(ServicesData).find(
    key => key.toLowerCase().replace(/[- ]/g, '') === normalizedServiceName
  );

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const serviceData = ServicesData[serviceKey]

  let formattedService = params.serviceDetailsId
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  const whyChooseUsKeys = Object.keys(serviceData?.whyChooseUs);
  const numberOfKeys = whyChooseUsKeys.length;

  return (
    <>
      <PageHeading
        title={formattedService}
        bgSrc='/images/header_bg.jpg'
        pageLinkText={''}
      />
      <Spacing lg='30' md='20' />
      <Div className="container p-4">
        <p className='mainServicesHeading px-lg-5'>{serviceData?.description}</p>
        <p className='mainServicesHeading px-lg-5'>{serviceData?.subDescription}</p>
      </Div>
      {/* <Div className="container">
        <h2 className="cs-section_title text-center">{serviceData?.processHeading}</h2>
        <Spacing lg='10' md='20' />
        <Div className="row">
          {serviceData?.developmentProcess?.map((data) => {
            return <Div className="col-lg-4 py-5">
              <Div className="text-center">
                <Div className="cs-iconbox_icon">
                  <img src={data.img} alt="Icon" />
                </Div>
                <h4 className="cs-iconbox_title pt-3">{data?.step}</h4>
                <Div className="cs-iconbox_subtitle">{data?.description}</Div>
              </Div>
            </Div>
          })}

        </Div>
      </Div> */}
      <Div className="container-lg">
        <Div className="row">
          {/* <SectionHeading
            title='Services we can help you with'
            subtitle=''
          /> */}
          <Spacing lg='10' md='45' />
          <Div className='col-12 d-flex flex-column align-items-center'>
            <p className='mainHeading mx-lg-5'>{serviceData.processHeading}</p>
            <Spacing lg='20' md='45' />
            <Timeline position="alternate" >
              {serviceData?.developmentProcess?.map((service, index) => (
                <TimelineItem key={index} >
                  <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                  >
                    <h2 className='timelineheading mx-lg-5'>{service.step}</h2>
                    <p className='timelineContent mx-lg-5'>{service.description}</p>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    {(index === 0) && <TimelineDot sx={{ backgroundColor: '#e2af26' }} />}
                    <TimelineConnector sx={{ backgroundColor: '#e2af26' }} />
                  </TimelineSeparator>
                  <TimelineContent >
                    <Div className="timeline-card cs-style1">
                      {/* {(index + 1) % 2 ? <div className={index === 0 ? 'sideIcon0' : 'sideIcon'}>
                        <img src={'/images/timelineIcon.png'} />
                      </div> : <div className='sideIconLeft'>
                        <img src={'/images/timeline2.png'} />
                      </div>} */}
                      <img src={service.icon} className={index === 0 ? 'sideIcon0' : (index + 1) % 2 ? 'sideIcon' : 'sideIconLeft'} loading="lazy" />
                      <img src={service.img} alt={'alt'} className={(index + 1) % 2 ? 'timeline-card-image' : 'timeline-card-image-left'} loading="lazy" />
                    </Div>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Div>
        </Div>
      </Div>
      <Spacing lg='100' md='50' />
      <Div className="container">
        <h2 className="cs-font_32 cs-m0 text-center mb-5" >Why Choose Brand Crest Digital?</h2>
        <Div className="row align-items-center">
          {serviceData?.whyChooseUs?.map((item,intex) => (<Div className={`${serviceData?.whyChooseUs?.length === 4 ? 'col-xl-3' : 'col-xl-4'} col-lg-4 col-md-6 my-4 py-2`} >
            <Div className='mainBox '>
              <CardContent className='pt-4'>
                {/* <Box display="flex" justifyContent="center" mb={2}> */}
                <Box
                  sx={{
                    width: 70,
                    height: 70,
                    backgroundColor: '#D9D9D9',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  className="cardIconBox"
                >
                  {/* <Icon fontSize="large" sx={{ color: 'black' }} /> */}
                  <img src={item.icon}  loading='lazy'/>
                </Box>
                <Typography variant="h6" sx={{ fontSize: '22px', fontWeight: '700', mb: 2 }} className='cardTitle pt-2'>
                  {/* {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())} */}
                  {item.title}
                </Typography>
                  {item.items.map((points)=>(<Typography variant="body2" sx={{ color: 'white', mb: 1 }} className='cardText '>
                    {points}
                  </Typography>))}
                
              </CardContent>
            </Div>
          </Div>
          ))}
          {/* <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/service_img_1.jpeg" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40' />
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_30 cs-m0">Why Choose Brand Crest Digital?</h2>
            <Spacing lg='30' md='30' />
            <Div className="row">
              <Div className="col-lg-12">
                {serviceData?.whyChooseUs && Object.keys(serviceData?.whyChooseUs).map((key) => (
                  <Div key={key}>
                    <h4 className="cs-iconbox_title " >{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</h4>
                    {serviceData?.whyChooseUs[key].map((item, index) => (
                      <Div>
                        <Button btnLink='' btnText={item} variant='cs-type2' auto={'auto'} />
                      </Div>
                    ))}
                  </Div>
                ))}
              </Div>

            </Div>
          </Div> */}
        </Div>
      </Div>
      <Spacing lg='90' md='80' />
      <Div className="mainExpertiseBg py-5" style={{ backgroundImage: 'url(/images/expertiseBg.png)', backgroundSize: '100% 100%' }}>
        <Div className='container'>
          <h2 className="mx-5 expertiseHeading">Areas of Expertise</h2>
          <Spacing lg='20' md='20' />
          <Div className="row">
            {serviceData?.areasOfExpertise && Object.keys(serviceData?.areasOfExpertise).map((key) => (
              <Div key={key} className='expertiseSection col-lg-4 col-md-6 col-sm-12'>
                <div className='px-5' >
                  <h4 className="cs-iconbox_title " style={{ textAlign: 'left' }}>{key.replace(/^./, str => str.toUpperCase())}</h4>
                  <ul style={{ listStyleType: 'unset', color: 'white' }}>
                    {serviceData?.areasOfExpertise[key].map((item, index) => (
                      <li key={index} className='mx-2' style={{ textAlign: 'left', textTransform: 'capitalize' }}>{item}</li>
                    ))}
                  </ul>
                </div>
              </Div>
            ))}
          </Div>
        </Div>
      </Div>
      {/* <Spacing lg='100' md='80' /> */}
      <TestimonialSlider />
      <Spacing lg='145' md='80' />
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Frequently asked questions'
                subtitle=''
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className="col-lg-12 ">
              <Accordion faqs={ServicesData[serviceKey]?.faqs} />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      <Div className="container">
        <Cta
          title='Let’s create something <br />extraordinary together'
          btnText='Book A Discovery Call'
          btnLink='/contact'
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    <Spacing lg='70' md='30' />
    <Cta
        title="hi@brandcrestdigital.com"
        bgSrc="/images/cta_bg_2.jpeg"
        variant="rounded-0"
      />
    </>
  )
}
