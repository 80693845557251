import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function ContactPage() {
  const navigate = useNavigate();
  pageTitle('Contact Us');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    projectType: "",
    mobile: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send form data to PHP endpoint
      const response = await axios.post(
        "https://brandcrestdigital.com/form-handler.php",
        new URLSearchParams(formData).toString(), // Convert data to form-encoded
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      );

      if (response.status === 200) {
        alert("Form submitted successfully.");
        setFormData({
          fullName: "",
          email: "",
          projectType: "",
          mobile: "",
          message: "",
        });
        navigate('/thankyou'); 
      } else {
        alert("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      alert("An error occurred while submitting the form.");
      console.error(error);
    }
  };

  return (
    <>
      <PageHeading
        title="Contact Us"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText=""
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Do you have a project <br/>in your mind?"
              subtitle="Getting Touch"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form onSubmit={handleSubmit} className="row">
              <div className="col-sm-6">
                <label className="cs-primary_color">Full Name*</label>
                <input
                  type="text"
                  className="cs-form_field"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
                <Spacing lg="20" md="20" />
              </div>
              <div className="col-sm-6">
                <label className="cs-primary_color">Email*</label>
                <input
                  type="email"
                  className="cs-form_field"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <Spacing lg="20" md="20" />
              </div>
              <div className="col-sm-6">
                <label className="cs-primary_color">Project Type*</label>
                <input
                  type="text"
                  className="cs-form_field"
                  name="projectType"
                  value={formData.projectType}
                  onChange={handleChange}
                  required
                />
                <Spacing lg="20" md="20" />
              </div>
              <div className="col-sm-6">
                <label className="cs-primary_color">Mobile*</label>
                <input
                  type="number"
                  className="cs-form_field"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                />
                <Spacing lg="20" md="20" />
              </div>
              <div className="col-sm-12">
                <label className="cs-primary_color">Message*</label>
                <textarea
                  cols="30"
                  rows="7"
                  className="cs-form_field"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>

                <Spacing lg="20" md="20" />
              </div>
              <div className="col-sm-12">
                <button type="submit" className="cs-btn cs-style1">
                  <span>Send Message</span>
                </button>
              </div>
            </form>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="cs-google_map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.2590597641134!2d-79.63484528470816!3d43.703484257117755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3ef0e562b00f%3A0x8a7e19e8d249e96e!2s2960%20Drew%20Rd%20Unit%20156%2C%20Mississauga%2C%20ON%20L4T%200A5%2C%20Canada!5e0!3m2!1sen!2sus!4v1698661304915!5m2!1sen!2sus"
          allowfullscreen
          title="Google Map"
        ></iframe>
      </Div>
    </>
  );
}
