import React, { useState } from 'react';
import Slider from 'react-slick';
import { Icon } from '@iconify/react';
import Testimonial from '../Testimonial';
import Div from '../Div';
import Spacing from '../Spacing';
const testimonialData = [
  {
    testimonialThumb: '/images/testimoinals/beddora logo for bcd-01.png',
    testimonialText:
      'Partnering with Brand Crest has been a game-changer for us. As a new brand, they expertly managed our social media and Amazon FBA, creating campaigns that resonated with our audience and built a solid online presence. Their FBA optimisation boosted product visibility, leading to a 40% sales increase in two months. Brand Crest significantly improved our sales rank and conversion rate with refined listings, keyword strategies, and effective ad management.',
    avatarName: 'Salman Rehan',
    avatarDesignation: 'CEO AT Beddora',
    ratings: '4',
  },
  {
    testimonialThumb: '/images/testimoinals/prostar logo for bcd-01.png',
    testimonialText:
      'Brand Crest developed a highly functional real estate web portal that boosted our online presence. In just three months, site traffic grew by 60%. Their targeted Google Ads increased qualified leads by 45%, while Meta Ads drove a 50% rise in inquiries from clients across the UAE, Asia, Europe, USA, and Canada. I highly recommend Brand Crest for any real estate brand looking to enhance its digital presence and drive results!',
    avatarName: 'Rehan Ijaz',
    avatarDesignation: 'CEO at Prostar Realty',
    ratings: '5',
  },
  {
    testimonialThumb: '/images/testimoinals/saldan home logo bcd-01.png',
    testimonialText:
      'As luxury custom home builders in Canada, we needed to elevate our brand and reach high-net-worth individuals. Our sleek, user-friendly website showcases our work exquisitely, and influencer marketing and Digital PR efforts were instrumental in positioning us as a top-tier brand in the luxury real estate market. Brand Crest is the team to trust!',
    avatarName: 'Khanjan Thakur',
    avatarDesignation: 'CMO AT Saldan Homes',
    ratings: '5',
  },
  {
    testimonialThumb: '/images/testimoinals/uls logo for bcd-01.png',
    testimonialText:
      'As a freight forwarding company targeting Canada and the USA, we needed an expert to help us generate high-quality leads through Google ads. Their Google Ads strategy focused on search ads. We saw a 55% increase in leads generated through Google searches. Brand Crest’s expert keyword targeting and ad optimisation led to a 40% reduction in cost per lead (CPL), significantly improving our ROI',
    avatarName: 'Anmol Verma',
    avatarDesignation: 'VP Marketing at ULS Freight Inc',
    ratings: '4.5',
  },
  {
    testimonialThumb: '/images/testimoinals/g force logo for bcd-01.png',
    testimonialText:
      'Brand Crest’s targeted approach generated high-quality inquiries, reducing our cost per lead by 35%. Their email campaigns achieved a 30% open rate. Their CRM system improved client communication, boosting retention by 25% and repeat business. Additionally, their branding efforts strengthened our presence across Canada through social media, print, and outdoor ads, building trust in the security industry.',
    avatarName: 'Danish Rehan',
    avatarDesignation: 'CEO AT G-Force Security',
    ratings: '5',
  },
  {
    testimonialThumb: '/images/testimoinals/sippa bcd logo-01.png',
    testimonialText:
      'Brand Crest transformed Sippa’s digital presence with creative Instagram, Google Ads, and influencer campaigns, boosting website traffic by 60% and sales by 45%. Their expertise in Amazon EBC optimised conversions, while their digital brand activation and premium e-commerce store enhanced the shopping experience. With the right positioning, Sippa is Canada go-to brand for stylish and functional drinkware.',
    avatarName: 'Douglas',
    avatarDesignation: 'Director Marketing at Sippa',
    ratings: '3.5',
  },
  {
    testimonialThumb: '/images/testimoinals/ytc logo for bcd-01.png',
    testimonialText:
      'Brand Crest helped us connect digitally with top-tier healthcare and IT candidates through strategic digital campaigns. Their efforts increased our visibility and led to a surge in high-quality applications. With their expertise in brand uplift, we’ve built stronger client relationships, enabling us to match the right talent with the right opportunities.',
    avatarName: 'Simran',
    avatarDesignation: 'CMO at Your Talent Consultancy',
    ratings: '4.5',
  },
  {
    testimonialThumb: '/images/testimoinals/247 gard logo for bcd-01.png',
    testimonialText:
      'Their responsiveness, flexibility, and forward-thinking approach have consistently met and exceeded our expectations. Over the past three years, they ave delivered exceptional results and have been a pleasure to work with. Brandcrest and his team’s expertise and dedication have genuinely supported our growth, and we look forward to continuing our successful partnership.',
    avatarName: 'Nav',
    avatarDesignation: 'Chief Marketing Officer at 247 Gard',
    ratings: '4',
  },
  {
    testimonialThumb: '/images/testimoinals/hudson security logo for bcd-01.png',
    testimonialText:
      'Brand Crest truly believes in the success of its clients, offering a wide range of expertise from digital marketing and SEO to website design. Their dedicated and responsive team is always open to new ideas and consistently delivers on our needs. We are incredibly grateful for their efforts in helping Hudson Security grow and succeed in the digital space, and we look forward to continuing our partnership.',
    avatarName: 'Shayan',
    avatarDesignation: 'CEO AT Hudson Security',
    ratings: '4',
  },
];

export default function TestimonialSlider() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  return (
    <>
      <Div className="cs-gradient_bg_1 cs-shape_wrap_3 cs-parallax">
        <Spacing lg="130" md="80" />
        <Div className="cs-shape_3 cs-to_up">
          {/* <img src="/images/shape_1.svg" alt="Shape" /> */}
        </Div>
        <Div className="container">
          <Div className="cs-testimonial_slider">
            <Div className="cs-testimonial_slider_left">
              <Slider
                asNavFor={nav1}
                ref={slider2 => setNav2(slider2)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                centerMode={true}
                centerPadding="0px"
                arrows={false}
              >
                {testimonialData.map((item, index) => (
                  <Div className="slider-nav_item" key={index}>
                    <Div className="cs-rotate_img">
                      <Div className="cs-rotate_img_in">
                        <img src={item.testimonialThumb} alt="Thumb" />
                      </Div>
                    </Div>
                  </Div>
                ))}
              </Slider>
            </Div>
            <Div className="cs-testimonial_slider_right">
              <Slider
                asNavFor={nav2}
                ref={slider1 => setNav1(slider1)}
                prevArrow={<SlickArrowLeft />}
                nextArrow={<SlickArrowRight />}
                className="cs-arrow_style1"
              >
                {testimonialData.map((item, index) => (
                  <Div key={index}>
                    <Testimonial
                      testimonialText={item.testimonialText}
                      avatarName={item.avatarName}
                      avatarDesignation={item.avatarDesignation}
                      ratings={item.ratings}
                    />
                  </Div>
                ))}
              </Slider>
            </Div>
          </Div>
        </Div>
        <Spacing lg="130" md="80" />
      </Div>
    </>
  );
}
