import React from 'react'
import Div from '../Div'

export default function Newsletter({ title, subtitle, placeholder }) {
  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <Div className="cs-newsletter cs-style1">
        <form
          action="#"
          className="cs-newsletter_form"
          onSubmit={async (e) => {
            e.preventDefault();
            const form = e.target; // Get the form element
            const email = form.elements.subscribeEmail.value; // Access the email value
            try {
              const response = await fetch('https://brandcrestdigital.com/form-handler.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: new URLSearchParams({ subscribeEmail: email })
              });
              const result = await response.json();
              alert(result.message);
              if (result.status === "success") {
                form.reset();
              }
            } catch (error) {
              alert('An error occurred. Please try again.');
            }
          }}
        >
          <input
            type="email"
            name="subscribeEmail"
            className="cs-newsletter_input"
            placeholder="Enter your email"
            required
          />
          <button type="submit" className="cs-newsletter_btn">
            <span>Send</span>
          </button>
        </form>
        <Div className="cs-newsletter_text">{subtitle}</Div>
      </Div>
    </>
  )
}
