import React from 'react';
import Div from '../Div';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import MenuWidget from '../Widget/MenuWidget';
import Newsletter from '../Widget/Newsletter';
import SocialWidget from '../Widget/SocialWidget';
import TextWidget from '../Widget/TextWidget';
import Spacing from '../Spacing';
import './footer.scss';
const copyrightLinks = [
  // {
  //   title: 'Terms of Use',
  //   href: '/',
  // },
  // {
  //   title: 'Privacy Policy',
  //   href: '/',
  // },
];

const serviceMenu = [
  {
    title: 'Home',
    href: '/',
  },
  {
    title: 'Services',
    href: '/service',
  },
  {
    title: 'Our Portfolio',
    href: '/portfolio',
  },
  {
    title: 'Blog',
    href: '/blog',
  },
  {
    title: 'Careers',
    href: '/careers',
  },
  {
    title: 'Contact',
    href: '/contact',
  },
];

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  return (
    <>
      <footer className="cs-fooer">
        <Div className="cs-fooer_main">
          <Div className="px-5">
            <Div className="row">
              <Div className="col-lg-3 col-sm-6 ">
                <Div className="cs-footer_item">
                  {/* <TextWidget
                  logoSrc="/images/Brandcrest Digital Logo new-01.png"
                  logoAlt="Logo"
                  text="Welcome to arino sed ut perspiciae omunde omnis iste natus error sitort voluptatem accusantium."
                  className="footer-logo"
                /> */}
                  <Div className="cs-text_widget">
                    <img src='/images/Brandcrest Digital Logo new-01.png' alt='footerLogo' style={{ width: '130px' }} loading='lazy' />
                    <p>Canada's premier digital marketing agency, driven by a passionate team of over 34 in-house crest creators.</p>
                  </Div>
                  <SocialWidget />
                </Div>
              </Div>
              <Div className="col-lg-3 col-sm-6 d-flex justify-content-lg-center justify-content-md-center">
                <Div className="cs-footer_item ">
                  <MenuWidget menuItems={serviceMenu} menuHeading="About" />
                </Div>
              </Div>
              <Div className="col-lg-3 col-sm-6 ">
                <Div className="cs-footer_item">
                  <ContactInfoWidget title="Contact Us" />
                </Div>
              </Div>
              <Div className="col-lg-3 col-sm-6 ">
                <Div className="cs-footer_item">
                  <Newsletter
                    title="Subscribe"
                    subtitle="Stay ahead of the curve with exclusive insights & updates-subscribe to  Brand Crest Digital's newsletter today!"
                    placeholder="example@gmail.com"
                  />
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
        <Div className="container d-flex justify-content-center">
          <Div className="cs-bottom_footer">
            <Div className="cs-bottom_footer_left">
              <Div className="cs-copyright ">Copyright © Brandcrest Digital.</Div>
            </Div>
            {/* <Div className="cs-bottom_footer_right">
            <MenuWidget menuItems={copyrightLinks} variant=" cs-style2" />
          </Div> */}
          </Div>
        </Div>
      </footer>
    </>
  );
}
