import React,{useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';

export default function Thankyou() {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleBackToHome = () => {
        navigate('/');
    };

    return (
        <div className="thank-you-container mx-2">
            <div className="thank-you-content">
                {/* <img src='/images/thankyou.jpg' style={{width:'300px'}}/> */}
                <Icon icon="material-symbols:check-circle-rounded" color='#e2af26' fontSize='100px' />
                <p style={{ textAlign: 'justify' }}>Thank you for getting in touch! Your submission has been successfully received, and we truly appreciate you taking the time to reach out. Our team will review your response and get back to you shortly if needed. Stay tuned, and thank you for your patience!</p>
                <button className="back-to-home-btn" onClick={handleBackToHome}>
                    Back to Home
                </button>
            </div>
        </div>
    );
}
