import React from 'react'
import { Link } from 'react-router-dom'
import Div from '../Div'
import './card.scss'

export default function Card({ title, link, src, alt, step }) {
  return (
    <Link to={link} className="cs-card cs-style1">
      <>
        <img src={src} alt={alt} loading='lazy'/>
        <Div className="cs-card_overlay" />
        <Div className="cs-card_info">
          <Div className="d-flex">
            <p className='my-2'><span className="cs-hover_layer3 cs-accent_bg" /></p>
            <h2 className="cs-card_title px-2" style={{textAlign:'left'}}>{title}</h2>
            {/* <p className='mx-2'>Step {step}</p> */}
          </Div>
          
        </Div>
      </>
    </Link>
  )
}
