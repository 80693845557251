import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Card from '../Card'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import PricingTableList from '../PricingTable/PricingTableList'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import PortfolioSlider2 from '../Slider/PortfolioSlider2'

export default function ServicesPage() {
  pageTitle('Service');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading
        title='Services'
        bgSrc='images/service_hero_bg.jpeg'
        pageLinkText=''
      />
      <Spacing lg='80' md='80' />
      <Div className='cs-shape_wrap_4'>
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="">
              <SectionHeading
                title='Services we can help you with'
                subtitle='What Can We Do'
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className='col-12 d-flex flex-column align-items-center'>
              {/* <Div className='row main-arrow-step'>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='UI/UX design'
                    link='/service/ui-ux-design'
                    src='/images/service_1.jpeg'
                    alt='Service'
                    step='1'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='React.js Development'
                    link='/service/reactjs-development'
                    src='/images/service_2.jpeg'
                    alt='Service'
                    step='2'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Digital Marketing'
                    link='/service/digital-marketing'
                    src='/images/service_3.jpeg'
                    alt='Service'
                    step='4'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Technology'
                    link='/service/technology'
                    src='/images/service_4.jpeg'
                    alt='Service'
                    step='3'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Consultancy'
                    link='/service/consultancy'
                    src='/images/service_5.jpeg'
                    alt='Service'
                    step='5'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Creative Design'
                    link='/service/creative-design'
                    src='/images/service_6.jpeg'
                    alt='Service'
                    step='6'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
              </Div> */}

              <Timeline position="alternate-reverse" className='servicesTimeLine'>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Card
                      title='Web Development'
                      link='/service/web-development'
                      src='/images/services/web development-01.jpg'
                      alt='Service'
                      step='1'
                    />
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Card
                      title='Performance Marketing'
                      link='/service/performance-marketing'
                      src='/images/services/performance marketing-01.jpg'
                      alt='Service'
                      step='2'
                    />
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Card
                      title='Content Management'
                      link='/service/content-management'
                      src='/images/services/content management-01.jpg'
                      alt='Service'
                      step='3'
                    />
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Card
                      title='CRM'
                      link='/service/CRM'
                      src='/images/services/crm-01.jpg'
                      alt='Service'
                      step='4'
                    />
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Card
                      title='Branding'
                      link='/service/Branding'
                      src='/images/services/branding-01.jpg'
                      alt='Service'
                      step='5'
                    />
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Card
                      title='Search Engine Optimization'
                      link='/service/Search-Engine-Optimization'
                      src='/images/services/seo-01.jpg'
                      alt='Service'
                      step='6'
                    />
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </Div>

          </Div>
        </Div>
      </Div>
      {/* <Spacing lg='150' md='80'/>
      <Div className="container">
        <SectionHeading
          title='Providing best <br/>pricing for client' 
          subtitle='Pricing & Packaging'
        />
        <Spacing lg='85' md='40'/>
        <PricingTableList/>
      </Div> */}
      <Spacing lg='110' md='80' />
      <Div className="container">
        <h2 className="cs-font_50 cs-m0 cs-line_height_4 d-flex justify-content-center">
          Our Portfolio
        </h2>
      </Div>
      <Spacing lg="90" md="70" />
      <PortfolioSlider2 />

      <Spacing lg='125' md='55' />
      <TestimonialSlider />
      <Spacing lg='150' md='80' />
      <Div className="container">
        <Cta
          title='Let’s create something <br />extraordinary together'
          btnText='Book A Discovery Call'
          btnLink='/contact'
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    <Spacing lg='70' md='30' />
    <Cta
        title="hi@brandcrestdigital.com"
        bgSrc="/images/cta_bg_2.jpeg"
        variant="rounded-0"
      />
    </>
  )
}
