import { Icon } from '@iconify/react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Portfolio from '../Portfolio';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';

const portfolioData = [
  {
    title: 'Website Development and Branding',
    subtitle: 'See Details',
    href: '/portfolio/Website Development and Branding',
    imgsrc: '/images/portfolio/1st.jpg',
    category: 'ui_ux_design',
  },
  {
    title: 'Build4U Rebranding',
    subtitle: 'See Details',
    href: '/portfolio/Build4U Rebranding',
    imgsrc: '/images/portfolio/build4u.jpg',
    category: 'logodesign',
  },
  {
    title: 'Sippa - Branding',
    subtitle: 'See Details',
    href: '/portfolio/Sippa - Branding',
    vediosrc: 'https://player.vimeo.com/video/1031321231?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&muted=1&controls=0&title=0&byline=0&portrait=0&loop=1',
    category: 'web_design',
  },
  {
    title: 'G Force Security - Video Production',
    subtitle: 'See Details',
    href: '/portfolio/G Force Security - Video Production',
    vediosrc: 'https://player.vimeo.com/video/1031321259?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&muted=1&controls=0&title=0&byline=0&portrait=0&loop=1',
    category: 'mobile_apps',
  },
  // {
  //   title: 'Content Marketing - Sippa',
  //   subtitle: 'See Details',
  //   href: '/portfolio/portfolio-details',
  //   imgsrc: '/images/portfolio_8.jpeg',
  //   category: 'ui_ux_design',
  // },
  {
    title: 'CGI Content - Beddora',
    subtitle: 'See Details',
    href: '/portfolio/CGI Content - Beddora',
    vediosrc: 'https://player.vimeo.com/video/1031321376?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&muted=1&controls=0&title=0&byline=0&portrait=0&loop=1',
    category: 'logodesign',
  },
  {
    title: 'Social Media - Prostar Reality',
    subtitle: 'See Details',
    href: '/portfolio/Social Media - Prostar Reality',
    vediosrc: 'https://player.vimeo.com/video/1031321308?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&muted=1&controls=0&title=0&byline=0&portrait=0&loop=1',
    category: 'branding',
  },
  {
    title: 'Brand Commercial - Saldan Homes',
    subtitle: 'See Details',
    href: '/portfolio/Brand Commercial - Saldan Homes',
    vediosrc: 'https://player.vimeo.com/video/1031321342?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&muted=1&controls=0&title=0&byline=0&portrait=0&loop=1',
    category: 'web_design',
  },
];


const categoryMenu = [
  {
    title: 'Web Development',
    category: 'web_design',
  },
  {
    title: 'Performance Marketing',
    category: 'ui_ux_design',
  },
  {
    title: 'Content Management',
    category: 'mobile_apps',
  },
  {
    title: 'CRM',
    category: 'logodesign',
  },
  {
    title: 'Branding',
    category: 'branding',
  },
  {
    title: 'SEO',
    category: 'seo',
  },
];

export default function PortfolioPage() {
  pageTitle('Portfolio');
  const [active, setActive] = useState('all');
  const [itemShow, setItemShow] = useState(10);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeading
        title="Our Portfolio"
        bgSrc="images/portfolio_hero_bg.jpeg"
        pageLinkText=""
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="Some recent work" subtitle="Our Portfolio" />
          <Div className="cs-filter_menu cs-style1">
            <ul className="cs-mp0 cs-center">
              <li className={active === 'all' ? 'active' : ''}>
                <span onClick={() => setActive('all')}>All</span>
              </li>
              {categoryMenu.map((item, index) => (
                <li
                  className={active === item.category ? 'active' : ''}
                  key={index}
                >
                  <span onClick={() => setActive(item.category)}>
                    {item.title}
                  </span>
                </li>
              ))}
            </ul>
          </Div>
        </Div>
        <Spacing lg="90" md="45" />
        <Div className="row">
          {portfolioData.slice(0, itemShow).map((item, index) => (
            <Div
              className={`${index === 0 || index === 6 ? 'col-lg-8' : 'col-lg-4'
                } ${active === 'all' || active === item.category ? '' : 'd-none'}`}
              key={index}
            >
              <Portfolio
                title={item.title}
                subtitle={item.subtitle}
                href={item.href}
                imgsrc={item?.imgsrc}
                vediosrc={item?.vediosrc}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>

        <Div className="text-center">
          {portfolioData.length <= itemShow ? (
            ''
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 3)}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
      <Cta
        title="hi@brandcrestdigital.com"
        bgSrc="/images/cta_bg_2.jpeg"
        variant="rounded-0"
      />
    </>
  );
}
