import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react';
import Div from '../Div';

export default function SocialWidget() {
  return (
    <Div className="cs-social_btns cs-style1">
      <Link to='https://www.linkedin.com/company/brandcrest-digital' className="cs-center" target='_blank'>
        <Icon icon="fa6-brands:linkedin-in" />
      </Link>
      <Link to='https://x.com/Brand_Crest' className="cs-center" target='_blank'>
        <Icon icon="fa6-brands:twitter" />
      </Link>
      {/* <Link to='/' className="cs-center">
        <Icon icon="fa6-brands:youtube" />              
      </Link> */}
      <Link to='https://www.instagram.com/brandcrest_digital/' className="cs-center" target='_blank'>
        <Icon icon="fa6-brands:instagram" />
      </Link>
      <Link to='https://www.tiktok.com/@brand.crest.digital' className="cs-center" target='_blank'>
        <Icon icon="fa6-brands:tiktok" />
      </Link>
      <Link to="https://www.pinterest.com/Brandcrest_Digital/" className="cs-center" target='_blank'>
        <Icon icon="fa6-brands:pinterest" />
      </Link>
      <Link to="https://www.facebook.com/BrandCrest.Digital/" className="cs-center" target='_blank'>
        <Icon icon="fa6-brands:facebook" />
      </Link>
    </Div>
  )
}
