import React from 'react';
import { Link } from 'react-router-dom';
import Div from '../Div';
 
export default function PageHeading({ title, bgSrc, pageLinkText }) {
  return (
    <Div
      className="cs-page_heading cs-style1 cs-center text-center cs-bg"
      // style={{ backgroundImage: `url(${bgSrc})` }}
      style={{ backgroundImage: `url(/images/header_bg.jpg)` }}
    >
      <Div className="container">
        <Div className="cs-page_heading_in">
          <h1 className="cs-page_title cs-font_50 cs-white_color">{title}</h1>
            {/* <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>   */}
            <p className="breadcrumb-item active">{pageLinkText}</p>
        </Div>
      </Div>
    </Div>
  ); 
}
