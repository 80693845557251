import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import CustomCursor from '../CustomCursor';
import Footer from '../Footer';
import Header from '../Header';
import Spacing from '../Spacing';
import Div from '../Div';
import { Icon } from '@iconify/react';

export default function Layout({ headerVariant }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header variant={headerVariant} />
      <Outlet />
      <CustomCursor />
      {/* <Spacing lg="70" md="80" />
      <Div className="d-flex justify-content-center">
        <p
          className='cs-text_btn'
          onClick={()=> window.scrollTo(0, 0)}
        >
          <>
            <span>Let the band stay black</span>
            <Icon icon="bi:arrow-up" />
          </>
        </p>
      </Div> */}
      <Footer />
    </>
  );
}
