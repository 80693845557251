import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import FunFact from '../FunFact';
import FunFact2 from '../FunFact/FunFact2';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import TeamSlider from '../Slider/TeamSlider';
import Spacing from '../Spacing';
const funfaceData = [
  {
    title: 'Crest Creators',
    factNumber: '34',
  },
  {
    title: 'CSAT Score',
    factNumber: '96.3%',
  },
  {
    title: 'Project completed',
    factNumber: '8K+',
  },
  {
    title: 'Video Production Studio',
    factNumber: 'In-House ',
  },
];

export default function AboutPage() {
  pageTitle('About');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Start Page Heading Section */}
      <PageHeading
        title="About Us"
        bgSrc="images/header_bg.jpg"
        pageLinkText=""
      />
      {/* End Page Heading Section */}

      {/* Start About Section */}
      <Spacing lg="90" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title="Your trusted partner for growth"
              subtitle="About Brandcrest Digital"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                Founded by <span style={{ color: 'white', fontWeight: "800" }}> Mahrukh Mubarak,</span> we are Canada's premier digital marketing agency, driven by a passionate team of over 34 in-house crest creators. We believe in creating digital experiences that truly work for our clients. Our incredible team uses their unparalleled knowledge to produce eye-catching, conversion-focused campaigns, and content that drive measurable results.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/about/image 1 about page-01.jpg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-7">
            <img
              src="/images/about/image 2 about page-01.jpg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-5">
            <img
              src="/images/about/image 3 about page-01.jpg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="75" md="55" />
      {/* End About Section */}
 
      {/* Start Fun Fact Section */}
      <Div className="container">
        {/* <FunFact
          title="Our fun fact"
          subtitle="Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis."
          data={funfaceData}
        /> */}

        <FunFact2
          data={funfaceData}
          variant="cs-no_shadow"
          bgUrl="/images/funfact_shape_bg.svg"
        />
      </Div>
      {/* End Fun Fact Section */}

      {/* Start Why Choose Section */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/about/image 4 about page-01.jpg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="User-First Advertising - Crafted by Our Crest Creators"
              subtitle="Why Choose BCD"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                We craft compelling online advertising from start to finish, always keeping the user experience at the forefront. At the heart of Brand Crest Digital is a vibrant, collaborative culture that fosters innovation. Our crest creators are the secret ingredient that makes our work extraordinary.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Why Choose Section */}

      {/* Start Team Section */}
      {/* <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Awesome team <br/>members"
          subtitle="Our Team"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        <TeamSlider />
      </Div> */}
      {/* End Team Section */}

      {/* Start CTA Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s create something <br />extraordinary together"
          btnText="Book A Discovery Call"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
      <Spacing lg='70' md='30' />
      <Cta
        title="hi@brandcrestdigital.com"
        bgSrc="/images/cta_bg_2.jpeg"
        variant="rounded-0"
      />
    </>
  );
}
