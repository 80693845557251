import React, { useEffect } from 'react'
import PageHeading from '../PageHeading'
import Spacing from '../Spacing'
import "../../scss/carrer.scss"
import { pageTitle } from '../../helper'
import { Link } from 'react-router-dom'

export default function Carrers() {
    pageTitle('Carrer');
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <PageHeading
                title="Careers"
                bgSrc="/images/contact_hero_bg.jpeg"
                pageLinkText=""
            />
            <Spacing lg="145" md="80" />
            <div className="container">
                <div className="grid-container">
                    <div className="grid-item col-span-2">
                        <h4 className="">
                            Let's Decode the Brand Code Together
                        </h4>
                    </div>
                    <div className="grid-item">
                        <p className="">
                            Here, your ideas find a platform, and your skills find purpose, creating a real impact in the digital world. Join us to elevate your career in an environment where passion and collaboration fuel success.
                        </p>
                    </div>
                </div>

                <div className="job-section py-4">
                    <div className="job-details col-span-4">
                        <div className="job-info">
                            <h6 className="job-type">Full Time</h6>
                            <p className="location">Mississauga</p>
                        </div>
                        <p className="job-title">UI & Web Developer</p>
                    </div>
                    <div className="job-description col-span-4 p-4">
                        <p >
                            Join us as a UI and web Developer, where you'll create beautiful, enjoyable, and user-friendly designs that make an impact. If you're ready to elevate the digital experience, let’s apply!
                        </p>
                    </div>

                    <div className="apply-button col-span-4">
                        <Link to='/contact' className="cs-center">
                            <button className="button">Apply Now</button>
                        </Link>
                    </div>

                </div>

                <div className="job-section">
                    <div className="job-details col-span-4">
                        <div className="job-info">
                            <h6 className="job-type">Full Time</h6>
                            <p className="location">Mississauga</p>
                        </div>
                        <p className="job-title">Performance Marketer</p>
                    </div>
                    <div className="job-description col-span-4 p-4">
                        <p>
                            We are hiring a savvy Performance Marketer. Step into a role where data meets creativity to fuel high-impact campaigns. We're excited to meet you if you’re all about driving growth and innovation!
                        </p>
                    </div>

                    <div className="apply-button col-span-4">
                        <Link to='/contact' className="cs-center">
                            <button className="button">Apply Now</button>
                        </Link>
                    </div>

                </div>

                <div className="job-section job-section-border">
                    <div className="job-details col-span-4">
                        <div className="job-info">
                            <h6 className="job-type">Full Time</h6>
                            <p className="location">Mississauga</p>
                        </div>
                        <p className="job-title">CRM Specialist</p>
                    </div>
                    <div className="job-description col-span-4 p-4">
                        <p>
                            Become our next CRM Specialist!  Leverage your talent for cultivating connections and turning data into meaningful strategies to shape remarkable customer experiences. Ready to make an impact? Let’s apply!
                        </p>
                    </div>

                    <div className="apply-button col-span-4" >
                        <Link to='/contact' className="cs-center">
                            <button className="button">Apply Now</button>
                        </Link>
                    </div>

                </div>

                <div className="requirements pt-5">
                    <h5 className="requirements-title">Why Join Brand Crest Digital?</h5>
                    <p className="job-description pt-3" >
                        Here, professional development isn’t just a box to check. It’s woven into every project, with opportunities to expand your skill set, take on new challenges, and learn from industry-leading professionals. You’ll be empowered to drive your career forward while making meaningful contributions to groundbreaking campaigns. As a colleague, you’ll help us drive the future of digital branding, making your mark on an industry that’s always in motion.
                    </p>
                    <p className="job-description">
                        Brand Crest is where you belong if you’re looking for a place to unleash your creativity, advance your career, and be part of a vibrant, collaborative community. Join us, and let’s shape the future of branding together.
                    </p>
                </div>
            </div>
            <Spacing lg='70' md='30' />

        </>
    )
}
