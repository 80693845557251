import React from 'react';
import { Link } from 'react-router-dom';
import Div from '../Div';
import './portfolio.scss';
import { Router } from 'react-router-dom';

export default function Portfolio({ href, imgsrc, vediosrc, title, subtitle, variant }) {

  return (
    <Link
      // to={href}
      className={`cs-portfolio cs-bg ${variant ? variant : 'cs-style1'}`}
    >
      {/* Render video if vediosrc is provided */}
      {vediosrc && (
        <>
          <Div className="cs-portfolio_hover" />
          <Div
            className="cs-portfolio_bg cs-bg"
            style={{ backgroundImage: `url("${imgsrc}")` }}
          >
            <div
              style={{
                padding: title === "Brand Commercial - Saldan Homes" ? '52.29% 0 0 0' : '109.43% 0 0 0',
                position: 'relative'
              }}
              dangerouslySetInnerHTML={{
                __html: `
            <iframe 
              src="${vediosrc}"
              frameborder="0" 
              allow="autoplay; fullscreen; picture-in-picture" 
              style="position:absolute;top:0;left:0;width:100%;height:100%;border-radius:15px;" 
              title="Video"
            ></iframe>
            <script src="https://player.vimeo.com/api/player.js"></script>
          `,
              }}
            />
          </Div>
        </>
      )}

      {/* Render image if imgsrc is provided and vediosrc is not */}
      {!vediosrc && imgsrc && (
        <>
          <Div className="cs-portfolio_hover" />
          <Div
            className="cs-portfolio_bg cs-bg"
            style={{ backgroundImage: `url("${imgsrc}")` }}
          />
        </>
      )}

      {/* Common content for both cases */}
      <Div className="cs-portfolio_info">
        <Div className="cs-portfolio_info_bg cs-accent_bg" />
        <h2 className="cs-portfolio_title" style={{ textAlign: 'left' }}>
          {title}
        </h2>
        <Div className="cs-portfolio_subtitle">{subtitle}</Div>
      </Div>
    </Link>
  );
}
