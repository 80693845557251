import { Icon } from '@iconify/react'
import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Div from '../Div'
import Sidebar from '../Sidebar.jsx'
import Spacing from '../Spacing'
import blogData from "../blogData.json"
import SectionHeading from '../SectionHeading'
import Accordion from '../Accordion/index.jsx'

export default function BlogDetailsPage() {
  const params = useParams()
  pageTitle('Blog Details');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const blogsData = blogData[params.blogDetailsId]

  return (
    <>
      {/* Start Page Heading Section */}
      <PageHeading
        title='Blog Details'
        bgSrc='/images/blog_details_hero_bg.jpeg'
        pageLinkText={''}
      />
      {/* End Page Heading Section */}

      {/* Start Blog Details */}
      <Spacing lg='80' md='80' />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-12">

            {/* Start Details Post Content */}
            <Div className="cs-post cs-style2">
              <Div className="cs-post_thumb cs-radius_15">
                <img src={blogsData?.img} alt="Post" className="w-100 cs-radius_15" loading='lazy' />
              </Div>
              <Div className="cs-post_info">
                <Div className="cs-post_meta cs-style1 cs-ternary_color cs-semi_bold cs-primary_font">
                  <span className="cs-posted_by">{blogsData?.date}</span>
                  {/* <Link to="/blog" className="cs-post_avatar">Tech</Link> */}
                </Div>
                <h2 className="cs-post_title">{blogsData?.title}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: blogsData.intro,
                  }}
                />
                {/* <blockquote className="cs-primary_font">
                  But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, who expound the actual teachings of the great explorer of the truth, the master.
                  <small>Loren Mulari</small>
                </blockquote> */}
                {/* <p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every pleasure is to be welcomed and every pain avoided. But in certain circumstances and owing to the claims of duty or the obligations of business it will frequently occur that pleasures have to be repudiated.</p> */}
                {/* <Div className="row">
                  <Div className="col-md-6">
                    <img src="/images/blog_details_img_1.jpeg" alt="Blog Details" className="cs-radius_15 w-100" />
                    <Div className="cs-height_45 cs-height_lg_45" />
                  </Div>
                  <Div className="col-md-6">
                    <img src="/images/blog_details_img_2.jpeg" alt="Blog Details" className="cs-radius_15 w-100" />
                    <Div className="cs-height_45 cs-height_lg_45" />
                  </Div>
                </Div> */}
                {blogsData?.sections?.map((item, key) => {
                  return (<>
                    <h3>{item.title}</h3>
                    {item.content.map((item, index) => (<p
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: item,
                      }}
                    />))}
                  </>)
                })}
              </Div>
            </Div>
            {/* End Details Post Content */}

            <Div className="container cs-shape_wrap_4">
              <Div className="cs-shape_4"></Div>
              <Div className="cs-shape_4"></Div>
              <Div className="">
                <Div className="row">
                  <Div className="col-xl-5 col-lg-6">
                    <SectionHeading
                      title='Frequently asked questions'
                      subtitle=''
                    />
                    <Spacing lg='90' md='45' />
                  </Div>
                  <Div className="col-lg-12 ">
                    <Accordion faqs={blogsData?.faqs} />
                  </Div>
                </Div>
              </Div>
            </Div>


            {/* Start Comment Section */}
            <Spacing lg='80' md='30' />
            <h2 className="cs-font_50 cs-m0">Leave A Reply</h2>
            <Spacing lg='5' md='5' />
            <p className="cs-m0">Your email address will not be published. Required fields are marked *</p>
            <Spacing lg='40' md='30' />
            <form className="row">
              <Div className="col-lg-6">
                <label>Full Name*</label>
                <input type="text" className="cs-form_field" />
                <Div className="cs-height_20 cs-height_lg_20" />
                <Div data-lastpass-icon-root="true" style={{ position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important' }} /></Div>
              <Div className="col-lg-6">
                <label>Email*</label>
                <input type="text" className="cs-form_field" />
                <Div className="cs-height_20 cs-height_lg_20" />
              </Div>
              <Div className="col-lg-12">
                <label>Website*</label>
                <input type="text" className="cs-form_field" />
                <Div className="cs-height_20 cs-height_lg_20" />
              </Div>
              <Div className="col-lg-12">
                <label>Write Your Comment*</label>
                <textarea cols={30} rows={7} className="cs-form_field" />
                <Div className="cs-height_25 cs-height_lg_25" />
              </Div>
              <Div className="col-lg-12">
                <button className="cs-btn cs-style1">
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
            {/* End Comment Section */}
          </Div>
          {/* <Div className="col-xl-3 col-lg-4 offset-xl-1">
            <Spacing lg='0' md='80'/>
            <Sidebar />
          </Div> */}
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      {/* Start Blog Details */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title='Let’s create something <br />extraordinary together'
          btnText='Book A Discovery Call'
          btnLink='/contact'
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
      {/* End CTA Section */}
      <Spacing lg='70' md='30' />
      <Cta
        title="hi@brandcrestdigital.com"
        bgSrc="/images/cta_bg_2.jpeg"
        variant="rounded-0"
      />
    </>
  )
}
