import React from 'react'
import { Icon } from '@iconify/react';

export default function ContactInfoWidget({ withIcon, title }) {
  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <ul className="cs-menu_widget cs-style1 cs-mp0">
        <li>
          {withIcon ? <span className='cs-accent_color'><Icon icon="material-symbols:add-call-rounded" /></span> : ''}
          <a
            href="https://wa.me/14379940648"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            +1437-994-0648
          </a>
        </li>
        <li>
          {withIcon ? <span className='cs-accent_color'><Icon icon="mdi:envelope" /></span> : ''}
          <a
            href="mailto:hi@brandcrestdigital.com"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            hi@brandcrestdigital.com
          </a>
        </li>
        <li>
          {withIcon ? <span className='cs-accent_color'><Icon icon="mdi:map-marker" /></span> : ''}
          2960 Drew Rd. Unit 156 - 1st Floor <br />Mississauga, On. L4T0A5
        </li>
      </ul>
    </>
  )
}
