import React from 'react';
import { useState } from 'react';
import Div from '../Div';

export default function VideoModal({ videoSrc, bgUrl, variant }) {
  const [iframeSrc, setIframeSrc] = useState('about:blank');
  const [toggle, setToggle] = useState(false);
  const handelClick = () => {
    // const video = videoSrc.split('?v=')[1].trim();
    // setIframeSrc(`https://www.youtube.com/embed/${video}`);
    setIframeSrc(`${videoSrc}`);
    setToggle(!toggle);
  };
  const handelClose = () => {
    setIframeSrc('about:blank');
    setToggle(!toggle);
  };
  return (
    <>
      <Div
        className={`cs-video_block ${variant ? variant : 'cs-style1'
          } cs-video_open cs-bg `}
        style={{ backgroundImage: `url(${bgUrl})`,backgroundSize:"100% 100%" }}
        onClick={handelClick}
      >
        <span className="cs-player_btn cs-accent_color">
          <span />
        </span>
      </Div>
      <Div className={toggle ? 'cs-video_popup active' : 'cs-video_popup'}>
        <Div className="cs-video_popup_overlay" />
        <Div className="cs-video_popup_content">
          <Div className="cs-video_popup_layer" />
          <Div className="cs-video_popup_container">
            <Div className="cs-video_popup_align">
              <Div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src={iframeSrc}
                  title="video modal"
                />
              </Div>
            </Div>
            <Div className="cs-video_popup_close" onClick={handelClose} />
          </Div>
        </Div>
      </Div>
      {/* <Div className="">
        <div
          style={{ padding: '56.25% 0 0 0', position: 'relative' }}
          dangerouslySetInnerHTML={{
            __html: `
          <iframe 
            src='https://player.vimeo.com/video/1030796397?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&muted=1&controls=0&title=0&byline=0&portrait=0&loop=1' 
            frameborder="0" 
            allow="autoplay; fullscreen; picture-in-picture" 
            style="position:absolute;top:0;left:0;width:100%;height:100%;border-radius:15px;" 
            title="Video"
          ></iframe>
          <script src="https://player.vimeo.com/api/player.js"></script>
        `
          }}
        />
      </Div> */}
    </>
  );
}
